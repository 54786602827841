import React from 'react';
import { Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 300000, // 5 minutes
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="min-h-screen flex flex-col">
        <Helmet>
          <html lang="en" />
          <title>Turkish Hospitals Directory | Healthcare Facilities in Turkey</title>
          <meta name="description" content="Comprehensive directory of hospitals in Turkey. Find detailed information about healthcare facilities, including locations, services, and contact details." />
          <meta name="keywords" content="Turkish hospitals, healthcare Turkey, medical facilities Turkey, hospitals directory, healthcare services" />
          <meta property="og:title" content="Turkish Hospitals Directory | Healthcare Facilities in Turkey" />
          <meta property="og:description" content="Comprehensive directory of hospitals in Turkey. Find detailed information about healthcare facilities, including locations, services, and contact details." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://turkishhospitals.org" />
          <meta property="og:image" content="https://turkishhospitals.org/og-image.jpg" />
          <meta property="og:site_name" content="Turkish Hospitals Directory" />
          <meta property="og:locale" content="en_US" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Turkish Hospitals Directory | Healthcare Facilities in Turkey" />
          <meta name="twitter:description" content="Comprehensive directory of hospitals in Turkey. Find detailed information about healthcare facilities, including locations, services, and contact details." />
          <meta name="twitter:image" content="https://turkishhospitals.org/og-image.jpg" />
          <meta name="robots" content="index, follow" />
          <meta name="language" content="English" />
          <meta name="revisit-after" content="7 days" />
          <meta name="author" content="Turkish Hospitals Directory" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <link rel="icon" type="image/png" href="/favicon.png" />
          <link rel="canonical" href="https://turkishhospitals.org" />
        </Helmet>
        <Navbar />
        <main className="flex-grow">
          <Outlet />
        </main>
        <Footer />
      </div>
    </QueryClientProvider>
  );
}

export default App;