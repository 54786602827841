import { useState } from 'react';
import { Hospital } from '../types/Hospital';
import { useAllHospitals } from '../hooks/useHospitals';
import { Search, MapPin } from 'lucide-react';
import Select from 'react-select/async';
import { StylesConfig } from 'react-select';

interface SearchBarProps {
  onSelect: (hospital: Hospital) => void;
  className?: string;
}

interface Option {
  label: string;
  value: string;
  data: Hospital;
}

const customStyles: StylesConfig<Option, false> = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? '2px solid #3b82f6' : '2px solid #e5e7eb',
    borderRadius: '1rem',
    minHeight: '3.5rem',
    boxShadow: state.isFocused ? '0 0 0 2px rgba(59, 130, 246, 0.1)' : 'none',
    backgroundColor: '#ffffff',
    transition: 'all 0.2s ease',
    '&:hover': {
      borderColor: '#3b82f6',
      cursor: 'pointer'
    }
  }),
  option: (base, state) => ({
    ...base,
    padding: '0.75rem 1rem',
    backgroundColor: state.isFocused ? '#eff6ff' : 'white',
    color: state.isFocused ? '#1e40af' : '#374151',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    '&:active': {
      backgroundColor: '#dbeafe'
    }
  }),
  input: (base) => ({
    ...base,
    color: '#374151',
    fontSize: '1rem',
    padding: '0.25rem'
  }),
  placeholder: (base) => ({
    ...base,
    color: '#9ca3af',
    fontSize: '1rem'
  }),
  singleValue: (base) => ({
    ...base,
    color: '#374151',
    fontSize: '1rem'
  }),
  menu: (base) => ({
    ...base,
    borderRadius: '1rem',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    border: '1px solid #e5e7eb',
    padding: '0.5rem',
    backgroundColor: '#ffffff'
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0.5rem 1rem'
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#9ca3af',
    '&:hover': {
      color: '#3b82f6'
    }
  }),
  clearIndicator: (base) => ({
    ...base,
    color: '#9ca3af',
    '&:hover': {
      color: '#ef4444'
    }
  }),
  noOptionsMessage: (base) => ({
    ...base,
    color: '#6b7280',
    padding: '1rem',
    textAlign: 'center',
    fontSize: '0.875rem'
  })
};

export default function SearchBar({ onSelect, className = '' }: SearchBarProps) {
  const { data: hospitals = [] } = useAllHospitals();
  const [inputValue, setInputValue] = useState('');

  const loadOptions = async (inputValue: string) => {
    const filteredOptions = hospitals
      .filter((hospital) => {
        const searchStr = inputValue.toLowerCase();
        return (
          hospital.name.toLowerCase().includes(searchStr) ||
          (hospital.description || '').toLowerCase().includes(searchStr) ||
          (hospital.address || '').toLowerCase().includes(searchStr)
        );
      })
      .map((hospital) => ({
        value: hospital.id,
        label: hospital.name,
        data: hospital,
      }));

    return filteredOptions;
  };

  const formatOptionLabel = ({ label, data }: Option) => (
    <div className="flex items-center gap-3">
      <div className="flex-shrink-0">
        <Search className="h-5 w-5 text-blue-600" />
      </div>
      <div className="flex-1 min-w-0">
        <p className="text-sm font-medium text-gray-900 truncate">
          {label}
        </p>
        {data.address && (
          <p className="text-xs text-gray-500 truncate flex items-center gap-1 mt-0.5">
            <MapPin className="h-3 w-3" />
            {data.address}
          </p>
        )}
      </div>
    </div>
  );

  return (
    <div className={`relative ${className}`}>
      <Select
        cacheOptions
        defaultOptions
        value={null}
        inputValue={inputValue}
        onInputChange={(newValue) => setInputValue(newValue)}
        onChange={(option) => {
          if (option) {
            onSelect(option.data);
            setInputValue('');
          }
        }}
        loadOptions={loadOptions}
        styles={customStyles}
        formatOptionLabel={formatOptionLabel}
        placeholder="Search hospitals by name, description, or location..."
        noOptionsMessage={({ inputValue }) => 
          inputValue.length > 0
            ? "No hospitals found"
            : "Start typing to search..."
        }
        className="w-full"
        classNamePrefix="hospital-search"
        isSearchable
        isClearable
      />
    </div>
  );
}