import React from 'react';
import { Hospital } from '../types/Hospital';
import { useFeaturedHospitals } from '../hooks/useHospitals';
import HospitalCard from './HospitalCard';
import LoadingSpinner from './LoadingSpinner';

interface FeaturedHospitalsProps {
  onSelect?: (hospital: Hospital) => void;
}

export default function FeaturedHospitals({ onSelect }: FeaturedHospitalsProps) {
  const { data: hospitals = [], isLoading } = useFeaturedHospitals();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[300px]">
        <LoadingSpinner />
      </div>
    );
  }

  if (hospitals.length === 0) {
    return null;
  }

  return (
    <section className="py-8 sm:py-12">
      <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4 sm:mb-8 text-center">
        Featured Hospitals
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 px-4 sm:px-6 max-w-6xl mx-auto">
        {hospitals.map((hospital: Hospital) => (
          <div key={hospital.id} className="w-full">
            <HospitalCard
              hospital={hospital}
              onClick={() => onSelect?.(hospital)}
              className="h-full"
            />
          </div>
        ))}
      </div>
    </section>
  );
}