import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { 
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route
} from 'react-router-dom';
import App from './App';
import HomePage from './pages/HomePage';
import HospitalDetails from './pages/HospitalDetails';
import HospitalsMap from './pages/HospitalsMap';
import './index.css';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<HomePage />} />
      <Route path="hospital/:id" element={<HospitalDetails />} />
      <Route path="map" element={<HospitalsMap />} />
    </Route>
  )
);

const container = document.getElementById('root');
if (!container) {
  throw new Error('Failed to find the root element');
}

const root = createRoot(container);

root.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);
