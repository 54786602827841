import React from 'react';
import { useAllHospitals } from '../hooks/useHospitals';
import { Hospital } from '../types/Hospital';
import { Helmet } from 'react-helmet';
import SearchBar from '../components/SearchBar';
import FeaturedHospitals from '../components/FeaturedHospitals';
import { useNavigate } from 'react-router-dom';
import { obfuscateQIDToNumber } from '../utils/qidObfuscation';

export default function HomePage() {
  const navigate = useNavigate();
  const { data: allHospitals = [] } = useAllHospitals();

  const handleHospitalSelect = (hospital: Hospital) => {
    const slug = obfuscateQIDToNumber(hospital.id);
    navigate(`/hospital/${slug}`);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>Turkish Hospitals Directory - Find Healthcare Facilities Across Turkey</title>
        <meta name="description" content="Comprehensive directory of hospitals in Turkey. Find detailed information about healthcare facilities, services, and locations across the country." />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block">Find Hospitals in</span>
            <span className="block text-blue-600">Turkey</span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Discover healthcare facilities across Turkey. Get detailed information about services,
            locations, and contact details.
          </p>
        </div>

        <div className="mt-10">
          <SearchBar onSelect={handleHospitalSelect} />
        </div>

        <div className="mt-16">
          <FeaturedHospitals onSelect={handleHospitalSelect} />
        </div>
      </div>
    </div>
  );
}