// A fixed offset number for obfuscation
export const QID_OFFSET = 12345;

/**
 * Obfuscate a Wikidata QID to a numeric slug.
 * @param qid - The Wikidata QID to obfuscate (e.g., "Q131350574")
 * @returns The obfuscated numeric slug
 */
export function obfuscateQIDToNumber(qid: string): number {
    if (!qid || !qid.startsWith('Q')) {
        throw new Error('Invalid QID format');
    }
    // Remove the "Q" and parse the remaining number
    const numberPart = parseInt(qid.substring(1), 10);
    if (isNaN(numberPart)) {
        throw new Error('Invalid QID format');
    }
    // Add the offset to obfuscate the number
    return numberPart + QID_OFFSET;
}

/**
 * Resolve an obfuscated numeric slug back to the original Wikidata QID.
 * @param slug - The obfuscated numeric slug
 * @returns The original Wikidata QID
 */
export function resolveNumberToQID(slug: number | string): string {
    // Convert string to number if needed
    const numericSlug = typeof slug === 'string' ? parseInt(slug, 10) : slug;
    
    if (isNaN(numericSlug)) {
        console.error('Invalid slug format:', { slug, numericSlug });
        throw new Error('Invalid slug format');
    }

    // Subtract the offset to reverse the obfuscation
    const originalNumber = numericSlug - QID_OFFSET;
    console.log('Resolving slug:', {
        input: slug,
        numericSlug,
        originalNumber,
        result: `Q${originalNumber}`
    });
    
    // Add the "Q" prefix to reconstruct the QID
    return `Q${originalNumber}`;
}
