import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { 
  Building2, 
  MapPin, 
  Award, 
  Calendar, 
  Users, 
  Star, 
  Stethoscope, 
  Building, 
  Cpu, 
  UserCircle, 
  ExternalLink,
  Globe,
  Facebook,
  Twitter,
  Instagram,
  Youtube,
  Linkedin
} from 'lucide-react';
import { useHospitalDetails } from '../hooks/useHospitals';
import { resolveNumberToQID } from '../utils/qidObfuscation';
import LoadingSpinner from '../components/LoadingSpinner';
import { useState } from 'react';

interface TabProps {
  label: string;
  icon: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
}

const Tab = ({ label, icon, isActive, onClick }: TabProps) => (
  <button
    onClick={onClick}
    className={`flex items-center gap-2 px-4 py-3 text-sm font-medium border-b-2 whitespace-nowrap
      ${isActive 
        ? 'border-blue-500 text-blue-600' 
        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
      }`}
  >
    {icon}
    {label}
  </button>
);

interface ExternalLink {
  type: 'website' | 'facebook' | 'twitter' | 'instagram' | 'youtube' | 'linkedin';
  title: string;
  url: string;
}

interface DetailedHospital {
  name: string;
  nameInTurkish?: string;
  description: string;
  location: {
    address: string;
  };
  yearEstablished: number | null;
  accreditations: string[];
  affiliations: string[];
  specialties: string[];
  keyTreatments?: string[];
  facilities: string[];
  technologies: string[];
  importantPeople: {
    name: string;
    title: string;
    specialization?: string;
  }[];
  externalLinks?: ExternalLink[];
}

export default function HospitalDetails() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('overview');

  if (!id) {
    navigate('/');
    return null;
  }

  let qid: string;
  try {
    qid = resolveNumberToQID(id);
  } catch (error) {
    navigate('/');
    return null;
  }

  const { hospital, detailedData, isLoading, error } = useHospitalDetails(qid);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!hospital || !detailedData) {
    navigate('/');
    return null;
  }

  const tabs = [
    { id: 'overview', label: 'Overview', icon: <Building2 className="h-4 w-4" /> },
    { id: 'specialties', label: 'Specialties', icon: <Stethoscope className="h-4 w-4" /> },
    { id: 'facilities', label: 'Facilities', icon: <Building className="h-4 w-4" /> },
    { id: 'technologies', label: 'Technologies', icon: <Cpu className="h-4 w-4" /> },
    { id: 'personnel', label: 'Key Personnel', icon: <UserCircle className="h-4 w-4" /> },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>{detailedData.name} - Turkish Hospitals Directory</title>
        <meta name="description" content={detailedData.description} />
      </Helmet>

      {/* Hero Section */}
      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
            <div>
              <h1 className="text-3xl font-bold text-gray-900">{detailedData.name}</h1>
              {detailedData.nameInTurkish && detailedData.nameInTurkish !== detailedData.name && (
                <p className="text-lg text-gray-600 mt-1">{detailedData.nameInTurkish}</p>
              )}
              {detailedData.location?.address && (
                <div className="flex items-center gap-2 mt-2 text-gray-600">
                  <MapPin className="h-5 w-5 text-gray-400" />
                  <p>{detailedData.location.address}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Tabs Navigation */}
      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex space-x-8 overflow-x-auto">
            {tabs.map((tab) => (
              <Tab
                key={tab.id}
                label={tab.label}
                icon={tab.icon}
                isActive={activeTab === tab.id}
                onClick={() => setActiveTab(tab.id)}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Tab Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {activeTab === 'overview' && (
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">About {detailedData.name}</h2>
            <p className="text-gray-600 mb-6">{detailedData.description}</p>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {detailedData.yearEstablished !== null && (
                <div className="flex items-start gap-3">
                  <Calendar className="h-5 w-5 text-blue-500 mt-1" />
                  <div>
                    <h3 className="font-medium">Established</h3>
                    <p className="text-gray-600">{detailedData.yearEstablished}</p>
                  </div>
                </div>
              )}
              
              {detailedData.accreditations.length > 0 && (
                <div className="flex items-start gap-3">
                  <Award className="h-5 w-5 text-blue-500 mt-1" />
                  <div>
                    <h3 className="font-medium">Accreditations</h3>
                    <p className="text-gray-600">{detailedData.accreditations.join(', ')}</p>
                  </div>
                </div>
              )}

              {detailedData.affiliations.length > 0 && (
                <div className="flex items-start gap-3">
                  <Users className="h-5 w-5 text-blue-500 mt-1" />
                  <div>
                    <h3 className="font-medium">Affiliations</h3>
                    <p className="text-gray-600">{detailedData.affiliations.join(', ')}</p>
                  </div>
                </div>
              )}

              {detailedData.externalLinks && detailedData.externalLinks.length > 0 && (
                <div className="flex items-start gap-3">
                  <Globe className="h-5 w-5 text-blue-500 mt-1" />
                  <div>
                    <h3 className="font-medium">External Links</h3>
                    <div className="flex flex-wrap gap-3 mt-2">
                      {detailedData.externalLinks.map((link: ExternalLink, index: number) => {
                        const IconComponent = (() => {
                          switch (link.type) {
                            case 'facebook':
                              return Facebook;
                            case 'twitter':
                              return Twitter;
                            case 'instagram':
                              return Instagram;
                            case 'youtube':
                              return Youtube;
                            case 'linkedin':
                              return Linkedin;
                            default:
                              return ExternalLink;
                          }
                        })();

                        return (
                          <a
                            key={index}
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center gap-1.5 px-3 py-1.5 text-sm text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-full transition-colors"
                          >
                            <IconComponent className="h-4 w-4" />
                            {link.title}
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {activeTab === 'specialties' && (
          <div className="bg-white rounded-lg shadow">
            <div className="p-6">
              <h2 className="text-xl font-semibold mb-4">Medical Specialties</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {detailedData.specialties.map((specialty, index) => (
                  <div key={index} className="flex items-center gap-2 p-3 rounded-lg border border-gray-200">
                    <Stethoscope className="h-5 w-5 text-blue-500" />
                    <span>{specialty}</span>
                  </div>
                ))}
              </div>
            </div>
            {detailedData.keyTreatments && detailedData.keyTreatments.length > 0 && (
              <div className="border-t p-6">
                <h3 className="text-lg font-semibold mb-4">Key Treatments</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {detailedData.keyTreatments.map((treatment, index) => (
                    <div key={index} className="flex items-center gap-2 p-3 rounded-lg border border-gray-200">
                      <Star className="h-5 w-5 text-blue-500" />
                      <span>{treatment}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        {activeTab === 'facilities' && (
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Facilities & Amenities</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {detailedData.facilities.map((facility, index) => (
                <div key={index} className="flex items-center gap-2 p-3 rounded-lg border border-gray-200">
                  <Building className="h-5 w-5 text-blue-500" />
                  <span>{facility}</span>
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === 'technologies' && (
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Medical Technologies</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {detailedData.technologies.map((tech, index) => (
                <div key={index} className="flex items-center gap-2 p-3 rounded-lg border border-gray-200">
                  <Cpu className="h-5 w-5 text-blue-500" />
                  <span>{tech}</span>
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === 'personnel' && (
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Key Personnel</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {detailedData.importantPeople.map((person, index) => (
                <div key={index} className="flex items-start gap-3 p-4 rounded-lg border border-gray-200">
                  <UserCircle className="h-8 w-8 text-blue-500" />
                  <div>
                    <h3 className="font-medium">{person.name}</h3>
                    <p className="text-gray-600">{person.title}</p>
                    {person.specialization && (
                      <p className="text-sm text-gray-500">{person.specialization}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}