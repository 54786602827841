import axios from 'axios';
import { Hospital } from '../types/Hospital';

const FEATURED_HOSPITALS = [
  'Q6058058',
  'Q20047274', 
  'Q116508012', 
  'Q19611822', 
];

const WIKIDATA_ENDPOINT = 'https://query.wikidata.org/sparql';

export const fetchFeaturedHospitals = async (): Promise<Hospital[]> => {
  const query = `
    SELECT DISTINCT ?hospital ?hospitalLabel ?coords ?address ?phone ?website ?description
      ?facebook ?twitter ?instagram ?youtube ?linkedin
    WHERE {
      VALUES ?hospital { ${FEATURED_HOSPITALS.map(id => `wd:${id}`).join(' ')} }
      ?hospital rdfs:label ?hospitalLabel;
               wdt:P625 ?coords.
      OPTIONAL { ?hospital wdt:P6375 ?address }
      OPTIONAL { ?hospital wdt:P1329 ?phone }
      OPTIONAL { ?hospital wdt:P856 ?website }
      OPTIONAL { ?hospital wdt:P2013 ?facebook }
      OPTIONAL { ?hospital wdt:P2002 ?twitter }
      OPTIONAL { ?hospital wdt:P2003 ?instagram }
      OPTIONAL { ?hospital wdt:P2397 ?youtube }
      OPTIONAL { ?hospital wdt:P4264 ?linkedin }
      OPTIONAL { 
        ?hospital schema:description ?description.
        FILTER(LANG(?description) = "en")
      }
      FILTER(LANG(?hospitalLabel) = "en")
    }
  `;

  const results = await executeQuery(query);
  
  // Deduplicate by hospital ID
  const uniqueHospitals = new Map();
  results.forEach(hospital => {
    if (!uniqueHospitals.has(hospital.id)) {
      uniqueHospitals.set(hospital.id, hospital);
    }
  });
  
  return Array.from(uniqueHospitals.values());
};

export const fetchAllHospitals = async (): Promise<Hospital[]> => {
  const query = `
    SELECT DISTINCT ?hospital ?hospitalLabel ?coords ?address ?phone ?website ?description
      ?facebook ?twitter ?instagram ?youtube ?linkedin
    WHERE {
      ?hospital wdt:P31/wdt:P279* wd:Q16917;
               wdt:P17 wd:Q43;
               rdfs:label ?hospitalLabel;
               wdt:P625 ?coords.
      OPTIONAL { ?hospital wdt:P6375 ?address }
      OPTIONAL { ?hospital wdt:P1329 ?phone }
      OPTIONAL { ?hospital wdt:P856 ?website }
      OPTIONAL { ?hospital wdt:P2013 ?facebook }
      OPTIONAL { ?hospital wdt:P2002 ?twitter }
      OPTIONAL { ?hospital wdt:P2003 ?instagram }
      OPTIONAL { ?hospital wdt:P2397 ?youtube }
      OPTIONAL { ?hospital wdt:P4264 ?linkedin }
      OPTIONAL { 
        ?hospital schema:description ?description.
        FILTER(LANG(?description) = "en")
      }
      FILTER(LANG(?hospitalLabel) = "en")
    }
    GROUP BY ?hospital ?hospitalLabel ?coords ?address ?phone ?website ?description
      ?facebook ?twitter ?instagram ?youtube ?linkedin
  `;

  return executeQuery(query);
};

const executeQuery = async (query: string): Promise<Hospital[]> => {
  try {
    const response = await axios.get(WIKIDATA_ENDPOINT, {
      params: {
        query,
        format: 'json',
      },
    });

    return response.data.results.bindings.map((binding: any) => ({
      id: binding.hospital.value.split('/').pop(),
      name: binding.hospitalLabel.value,
      coordinates: {
        lat: parseFloat(binding.coords.value.split('Point(')[1].split(' ')[1]),
        lng: parseFloat(binding.coords.value.split('Point(')[1].split(' ')[0]),
      },
      address: binding.address?.value || '',
      phone: binding.phone?.value || '',
      website: binding.website?.value || '',
      description: binding.description?.value || '',
      socialLinks: {
        facebook: binding.facebook?.value || '',
        twitter: binding.twitter?.value || '',
        instagram: binding.instagram?.value || '',
        youtube: binding.youtube?.value || '',
        linkedin: binding.linkedin?.value || '',
      }
    }));
  } catch (error) {
    console.error('Error fetching hospitals:', error);
    return [];
  }
};