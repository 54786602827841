import { useQuery } from '@tanstack/react-query';
import { fetchAllHospitals, fetchFeaturedHospitals } from '../services/wikidata';
import { fetchDetailedHospitalData } from '../services/hospitalDetails';
import axios from 'axios';

const WIKIDATA_ENDPOINT = 'https://query.wikidata.org/sparql';

const formatSocialMediaUrl = (type: string, value: string): string => {
  switch (type) {
    case 'facebook':
      return `https://facebook.com/${value}`;
    case 'twitter':
      return `https://twitter.com/${value}`;
    case 'instagram':
      return `https://instagram.com/${value}`;
    case 'youtube':
      return value.startsWith('http') ? value : `https://youtube.com/${value}`;
    case 'linkedin':
      return value.startsWith('http') ? value : `https://linkedin.com/company/${value}`;
    case 'website':
      return value.startsWith('http') ? value : `https://${value}`;
    default:
      return value;
  }
};

async function fetchExternalLinks(id: string) {
  const sparqlQuery = `
    SELECT ?linkType ?link WHERE {
      wd:${id} ?linkProperty ?link .
      VALUES (?linkProperty ?linkType) {
        (wdt:P856 "website")
        (wdt:P2013 "facebook")
        (wdt:P2002 "twitter")
        (wdt:P2003 "instagram")
        (wdt:P2397 "youtube")
        (wdt:P4264 "linkedin")
      }
    }
  `;

  try {
    const response = await axios.get(WIKIDATA_ENDPOINT, {
      params: {
        query: sparqlQuery,
        format: 'json',
      },
      headers: {
        'Accept': 'application/json',
      },
    });

    const results = response.data.results.bindings;
    return results.map((result: any) => {
      const type = result.linkType.value;
      const rawValue = result.link.value;
      // Remove any protocol prefixes if they exist for social media handles
      const cleanValue = type !== 'website' ? 
        rawValue.replace(/^https?:\/\/(www\.)?(twitter\.com\/|facebook\.com\/|instagram\.com\/|youtube\.com\/|linkedin\.com\/(company\/)?)?/, '') :
        rawValue;
      
      return {
        type,
        title: type.charAt(0).toUpperCase() + type.slice(1),
        url: formatSocialMediaUrl(type, cleanValue)
      };
    });
  } catch (error) {
    console.error('Error fetching external links:', error);
    return [];
  }
}

export const useAllHospitals = () => {
  return useQuery({
    queryKey: ['allHospitals'],
    queryFn: fetchAllHospitals,
  });
};

export const useFeaturedHospitals = () => {
  return useQuery({
    queryKey: ['featuredHospitals'],
    queryFn: fetchFeaturedHospitals,
  });
};

export const useHospitalDetails = (id: string | undefined) => {
  const { data: hospitals = [], ...basicQuery } = useQuery({
    queryKey: ['allHospitals'],
    queryFn: fetchAllHospitals,
  });

  const { data: detailedData, ...detailedQuery } = useQuery({
    queryKey: ['hospitalDetails', id],
    queryFn: () => fetchDetailedHospitalData(id!),
    enabled: !!id,
    retry: false,
  });

  const { data: externalLinks = [] } = useQuery({
    queryKey: ['hospitalExternalLinks', id],
    queryFn: () => fetchExternalLinks(id!),
    enabled: !!id,
  });

  const basicData = hospitals.find(h => h.id === id);

  // If we have basic data but no detailed data, create a minimal detailed structure
  const fallbackDetailedData = !detailedData && basicData ? {
    wikidataId: basicData.id,
    name: basicData.name,
    nameInTurkish: basicData.name,
    description: basicData.description || '',
    shortDescription: basicData.description || '',
    location: {
      address: basicData.address || '',
      city: '',
      country: 'Turkey',
      coordinates: [0, 0]
    },
    specialties: [],
    keyTreatments: [],
    facilities: [],
    technologies: [],
    importantPeople: [],
    externalLinks: [],
    accreditations: [],
    researchAreas: [],
    yearEstablished: null,
    affiliations: [],
    seoKeywords: []
  } : null;

  const mergedDetailedData = detailedData ? {
    ...detailedData,
    externalLinks
  } : fallbackDetailedData;

  return {
    hospital: basicData,
    detailedData: mergedDetailedData,
    isLoading: basicQuery.isLoading || detailedQuery.isLoading || externalLinks.length === 0,
    error: basicQuery.error || detailedQuery.error,
  };
};