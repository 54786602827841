import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Building2, Map } from 'lucide-react';

export default function Navbar() {
  const location = useLocation();

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <Link to="/" className="flex items-center gap-2">
            <Building2 className="h-6 w-6 text-blue-600" />
            <span className="font-semibold text-gray-900">Turkish Hospitals</span>
          </Link>
          
          <div className="flex items-center gap-6">
            <Link
              to="/"
              className={`flex items-center gap-2 px-3 py-2 rounded-md ${
                location.pathname === '/' 
                  ? 'text-blue-600 bg-blue-50' 
                  : 'text-gray-600 hover:text-blue-600'
              }`}
            >
              <Building2 className="h-5 w-5" />
              <span>Hospitals</span>
            </Link>
            
            <Link
              to="/map"
              className={`flex items-center gap-2 px-3 py-2 rounded-md ${
                location.pathname === '/map' 
                  ? 'text-blue-600 bg-blue-50' 
                  : 'text-gray-600 hover:text-blue-600'
              }`}
            >
              <Map className="h-5 w-5" />
              <span>Map</span>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}