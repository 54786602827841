import { DetailedHospital } from '../types/DetailedHospital';

export const fetchDetailedHospitalData = async (id: string): Promise<DetailedHospital | null> => {
  try {
    const data = await import(`../data/hospitals/${id}.json`);
    return data.default;
  } catch (error) {
    console.log(`No detailed data found for hospital ${id}`);
    return null;
  }
};
