import React from 'react';
import { Hospital } from '../types/Hospital';
import { Phone, Globe, MapPin, ArrowRight, Building2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import { obfuscateQIDToNumber } from '../utils/qidObfuscation';

interface HospitalCardProps {
  hospital: Hospital;
  className?: string;
  onClick?: () => void;
}

export default function HospitalCard({ hospital, className = '', onClick }: HospitalCardProps) {
  const slug = obfuscateQIDToNumber(hospital.id);
  
  return (
    <div 
      className={`bg-white shadow rounded-lg overflow-hidden cursor-pointer ${className}`}
      onClick={onClick}
    >
      <article className="card p-6 group">
        <h2 className="text-2xl font-semibold mb-4 group-hover:text-blue-600 transition-colors">
          <Link
            to={`/hospital/${slug}`}
            className="hover:text-blue-600 transition-colors"
            onClick={(e) => {
              e.preventDefault();
              onClick?.();
            }}
          >
            {hospital.name}
          </Link>
        </h2>
        
        {hospital.description && (
          <p className="text-gray-600 mb-4 line-clamp-3">
            {hospital.description}
          </p>
        )}
        
        {hospital.address && (
          <div className="icon-wrapper mb-3">
            <MapPin className="h-5 w-5 text-blue-500" />
            <p className="text-gray-600 line-clamp-2">{hospital.address}</p>
          </div>
        )}
        
        {hospital.phone && (
          <div className="icon-wrapper mb-3">
            <Phone className="h-5 w-5 text-blue-500" />
            <a href={`tel:${hospital.phone}`} 
               className="text-blue-600 hover:underline"
               aria-label={`Call ${hospital.name}`}>
              {hospital.phone}
            </a>
          </div>
        )}
        
        {hospital.website && (
          <div className="icon-wrapper mb-4">
            <Globe className="h-5 w-5 text-blue-500" />
            <a href={hospital.website} 
               target="_blank" 
               rel="noopener noreferrer" 
               className="text-blue-600 hover:underline"
               aria-label={`Visit ${hospital.name} website`}>
              Visit Website
            </a>
          </div>
        )}
        
        <Link 
          to={`/hospital/${slug}`}
          className="group flex items-center gap-2 btn-primary w-full justify-center"
          aria-label={`View details of ${hospital.name}`}
          onClick={(e) => {
            e.preventDefault();
            onClick?.();
          }}
        >
          View Details
          <ArrowRight className="h-4 w-4 group-hover:translate-x-1 transition-transform" />
        </Link>
      </article>
    </div>
  );
}