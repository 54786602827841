import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchAllHospitals } from '../services/wikidata';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LoadingSpinner from '../components/LoadingSpinner';
import { useLeafletIcon } from '../hooks/useLeafletIcon';
import 'leaflet/dist/leaflet.css';
import { LatLngTuple } from 'leaflet';
import { obfuscateQIDToNumber } from '../utils/qidObfuscation';

export default function HospitalsMap() {
  useLeafletIcon();
  const navigate = useNavigate();

  const { data: hospitals = [], isLoading } = useQuery({
    queryKey: ['allHospitals'],
    queryFn: fetchAllHospitals,
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  // Calculate center of Turkey
  const center: LatLngTuple = [39.9334, 32.8597]; // Ankara coordinates

  return (
    <>
      <Helmet>
        <title>Hospitals Map | Turkish Hospitals Directory</title>
        <meta name="description" content="Interactive map showing the locations of hospitals across Turkey. Find hospitals near you and get detailed information." />
      </Helmet>

      <div className="h-[calc(100vh-64px)]">
        <MapContainer
          center={center}
          zoom={6}
          style={{ height: '100%', width: '100%' }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {hospitals.map((hospital) => {
            const position: LatLngTuple = [hospital.coordinates.lat, hospital.coordinates.lng];
            return (
              <Marker
                key={hospital.id}
                position={position}
                eventHandlers={{
                  click: () => {
                    const slug = obfuscateQIDToNumber(hospital.id);
                    navigate(`/hospital/${slug}`);
                  },
                }}
              >
                <Popup>
                  <div className="p-2">
                    <h3 className="font-semibold mb-2">{hospital.name}</h3>
                    {hospital.address && (
                      <p className="text-sm text-gray-600 mb-2">{hospital.address}</p>
                    )}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        const slug = obfuscateQIDToNumber(hospital.id);
                        navigate(`/hospital/${slug}`);
                      }}
                      className="mt-2 text-sm text-blue-600 hover:text-blue-800"
                    >
                      View Details
                    </button>
                  </div>
                </Popup>
              </Marker>
            );
          })}
        </MapContainer>
      </div>
    </>
  );
}